import { Grid } from '@mui/material';
import React from 'react';
import { FullHeight } from '../../../components/FullHeight';
import { SlideContent } from '../parts/SlideContent';
import { SectionHeader, TextLink } from '../../../components/Text';
import { services } from '../parts/Services';
import { SectionInfo } from '../parts/SectionInfo';
import styled from 'styled-components';

export function SlideServices() {
    const [activeService, setActiveService] = React.useState(1);

    const handleChangeService = (index: number) => () => {
        setActiveService(index);
    };

    return (
        <FullHeight container>
            <Grid container alignItems={'center'}>
                <Grid item xs={12} md={6}>
                    <SlideContent>
                        {services.map(({ link, text }, index: number) => (
                            <MenuItem
                                link={link}
                                active={activeService === index + 1}
                                onMouseEnter={handleChangeService(index + 1)}
                            >
                                {text}
                            </MenuItem>
                        ))}
                    </SlideContent>
                </Grid>
                <Grid item xs={0} md={6} alignItems="center">
                    <ImagesContainer>
                        <Image index={1} active={activeService === 1} />
                        <Image index={2} active={activeService === 2} />
                        <Image index={3} active={activeService === 3} />
                        <Image index={4} active={activeService === 4} />
                    </ImagesContainer>
                </Grid>
            </Grid>

            <SectionInfo>
                <SectionHeader>ZAKRES USŁUG&nbsp;</SectionHeader>
            </SectionInfo>
        </FullHeight>
    );
}

interface IMenuItemProps {
    active: boolean;
    link: string;
    children: React.ReactNode;
    onMouseEnter(): void;
}

function MenuItem(props: IMenuItemProps) {
    return <TextLink {...props} />;
}

function Image(props: any) {
    const indexClass = props.index ? `landing-page-services__image--${props.index}` : '';
    const isActiveClass = props.active ? 'landing-page-services__image--active' : '';

    return <div {...props} className={`landing-page-services__image ${isActiveClass} ${indexClass}`} />;
}

const ImagesContainer = styled.div`
    position: relative;
    height: 100vh;
    max-height: -webkit-fill-available;
    overflow: hidden;
`;
