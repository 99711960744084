import { Grid } from '@mui/material';
import React from 'react';
import { Text } from '../components/Text';
import { SubPageTheme } from '../components/SubPageTheme';
import { SubPageServiceTheme } from './SubPageServiceTheme';

const texts = [
    'Jestem absolwentem Wydziału Prawa i Administracji Uniwersytetu Łódzkiego oraz stypendystą rektora Uniwersytetu Łódzkiego.',
    'Ukończyłem aplikację radcowską przy Okręgowej Izbie Radców Prawnych w Łodzi, a po złożeniu egzaminu zawodowego zostałem tam wpisany na listę radców prawnych.',
    'Swoje doświadczenie zawodowe nabyłem w pracy m. in. dla kancelarii obsługującej jeden z największych podmiotów rynku finansowego w Polsce oraz w jednej z największych w województwie łódzkim kancelarii windykacyjnych. Mam wieloletnie doświadczenie w zastępstwie procesowym przed sądami powszechnymi na terenie całego kraju.',
    'Prywatnie jestem medalistą Mistrzostw Polski i Europy, instruktorem oraz sędzią okręgowym Polskiego Związku Karate Tradycyjnego. Uważam, że osiągnięcia w sporcie miały w moim życiu bezpośrednie przełożenie na pracę zawodową, w której cechuje mnie rzetelność, profesjonalizm i efektywność.',
];

export const PageAbout = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_Radca_prawny_z_waga.jpg">
            <SubPageServiceTheme header="O Kancelarii">
                {texts.map((text) => (
                    <Text>{text}</Text>
                ))}
            </SubPageServiceTheme>
        </SubPageTheme>
    );
};
