import React from 'react';
import { Grid } from '@mui/material';
import { InformationSecondaryLink, Text } from '../../../components/Text';
import { Button } from '../../../components/Button/Button';
import { Link } from 'react-router-dom';
import { SlideContent } from '../parts/SlideContent';
import { Links } from '../../../constants/links';
import styled from 'styled-components';

export const SlideAbout = () => {
    return (
        <Container container>
            <Grid item xs={12} md={6}>
                <SlideContent container>
                    <Text>
                        W ramach prowadzonej Kancelarii Radcy Prawnego świadczę usługi prawne zarówno na rzecz klientów
                        indywidualnych, jak i przedsiębiorców.
                    </Text>
                    <Text>Moim Klientom zapewniam kompleksową pomoc prawną na najwyższym poziomie.</Text>
                    <ImageContainer container justifyContent="flex-end">
                        <img src="resources/icons/podpis.png" alt="Kancelaria Olczyk" />
                    </ImageContainer>
                    <KnowMore container justifyContent="flex-end">
                        <InformationSecondaryLink link={Links.About}>DOWIEDZ SIĘ WIĘCEJ</InformationSecondaryLink>
                    </KnowMore>
                </SlideContent>
            </Grid>
        </Container>
    );
};

const Container = styled(Grid)`
    padding-top: 18%;
    height: 100vh;
    max-height: -webkit-fill-available;

    ${(props) => props.theme.breakpoints.down('md')} {
        padding-top: 75px;
        padding-bottom: 75px;
        height: auto;
        background: #eff7fa;
    }
`;

const ImageContainer = styled(Grid)`
    padding-top: 20px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        padding-top: 0;
    }
`;

export const KnowMore = styled(Grid)`
    padding-top: 60px;

    ${(props) => props.theme.breakpoints.down('lg')} {
        padding-top: 30px;
    }
`;
