import styled from 'styled-components';

export const SectionInfo = styled.div`
    position: absolute;
    bottom: 70px;
    left: 50%;
    transform: translateX(-50%);

    width: 30%;
    color: #083e71;
    border-bottom: 1px solid #083e71;
    /*padding-bottom: 10px;*/
    text-align: center;
`;
