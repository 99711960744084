import { Grid } from '@mui/material';
import React from 'react';
import { Specializations } from '../../Specializations';
import styled from 'styled-components';
import { SectionInfo } from '../parts/SectionInfo';
import { FullHeight } from '../../../components/FullHeight';
import { SectionHeader } from '../../../components/Text';

export const SlideSpecializations = () => {
    return (
        <SpecializationContainer>
            <Container container justifyContent="center">
                <Grid item md={12}>
                    <Specializations />
                </Grid>
            </Container>

            <SectionInfo>
                <SectionHeader>SPECJALIZACJE</SectionHeader>
            </SectionInfo>
        </SpecializationContainer>
    );
};

const SpecializationContainer = styled(FullHeight)`
    background: #fff;
    position: relative;
`;

const Container = styled(Grid)`
    position: relative;
    padding-top: 80px;
    padding-bottom: 50px;
    z-index: 2;

    ${(props) => props.theme.breakpoints.up('xl')} {
        padding-top: 300px;
        max-width: 50%;
        margin: 0 auto;
    }
`;
