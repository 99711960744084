import React from 'react';
import styled from 'styled-components';
import { Link as RRDLink } from 'react-router-dom';

// #083e71
// #6c7f96
// #eff7fa

export const Link = (props: any) => {
    function handleClick() {
        window.scroll(0, 0);
    }

    return <RRDLink {...props} to={props.to} onClick={handleClick} />;
};

// strona glowna
// tekst w slajdach mniejszych
interface ITextProps {
    active?: boolean;
}

export const Text = styled.p<ITextProps>`
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 31px;
    padding: 10px 0;

    color: ${(props) => (props.active ? '#083e71' : '#6c7f96')};

    & > a {
        color: ${(props) => (props.active ? '#083e71' : '#6c7f96')};

        &:hover,
        &:focus {
            color: #083e71;
        }
    }

    ${(props) => props.theme.breakpoints.down('md')} {
        font-size: 20px;

        & > a {
            font-size: 18px;
        }
    }
`;

// header w navie u gory
// opis slajdu na dole z podkresleniem
export const TextSecondary = styled.p<{ color?: string }>`
    font-family: 'Hind', serif;
    color: #083e71;
    font-weight: 600;

    font-size: 22px;
    letter-spacing: 6px;
`;

export const SectionHeader = styled(TextSecondary)`
    font-family: 'Hind', serif;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 5px;

    color: ${(props) => (props.color === 'primary' ? 'white' : '#083e71')};
`;

interface IBaseLinkProps {
    active?: boolean;
    link: string;
    children: React.ReactNode;
}

export const TextLink = (props: IBaseLinkProps) => {
    const { link, children } = props;

    return (
        <Text {...props}>
            <Link to={link}>{children}</Link>
        </Text>
    );
};

interface IInformation {
    bold?: boolean;
}

// Specjalizacje
export const Information = styled.p<IInformation>`
    font-family: 'Playfair Display', serif;
    font-size: 16px;
    line-height: 29px;
    font-weight: ${(props) => (props.bold ? '600' : '500')};
    color: #083e71;
`;

// kontakt - informacje
export const InformationSecondary = styled(Information)`
    font-family: Hind, serif;
    font-size: 17px;
    font-weight: 300;
`;

interface IInformationSecondaryLinkProps {
    link?: string;
    children: React.ReactNode;
}

// dowiedz sie wiecej
// linki na kontakcie
export const InformationSecondaryLink = ({ link, children }: IInformationSecondaryLinkProps) => (
    <InformationSecondary>
        <Link to={link}>{children}</Link>
    </InformationSecondary>
);

export const CopyRight = styled.p<{ bold?: boolean }>`
    font-family: 'Hind', serif;
    font-size: 15px;
    color: #083e71;
    font-weight: ${(props) => (props.bold ? '600' : 'inherit')};
`;

export const CopyLink = styled.p`
    font-family: 'Hind', serif;
    font-size: 10px;
    letter-spacing: 2px;
`;

interface IFooterLinkProps {
    link: string;
    children: React.ReactNode;
}

export const FooterLink = ({ link, children }: IFooterLinkProps) => (
    <CopyLink>
        <Link to={link}>{children}</Link>
    </CopyLink>
);

export const FooterParagraph = styled.p`
    font-family: 'Hind', serif;
    color: white;
    font-size: 8px;
    font-weight: 500;
    letter-spacing: 1px;

    padding: 0 20px 5px;
    text-align: center;

    &::last-child {
        padding-bottom: 0;
    }
`;

export const PageHeader = styled.p`
    font-family: 'Playfair Display', serif;
`;

export const PageText = styled.p`
    font-family: 'Playfair Display', serif;
`;

export const KnowMore = styled.p`
    color: #083e71;
    font-family: 'Hind', serif;
    font-size: 8px;
`;
