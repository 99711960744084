import React from 'react';
import { Grid } from '@mui/material';
import { SubPageTheme } from '../components/SubPageTheme';
import { Specializations } from './Specializations';
import styled from 'styled-components';

export const PageSpecializations = () => {
    return (
        <SubPageTheme headerBg="/resources/images/TOP_specjalizacje.jpg">
            <Container container justifyContent="center">
                <Specializations />
            </Container>
        </SubPageTheme>
    );
};

const Container = styled(Grid)`
    margin-top: -50px;
    margin-bottom: 180px;
`;
