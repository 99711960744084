import React from 'react';
import { Grid } from '@mui/material';
import { Footer } from '../modules/Footer';
import styled from 'styled-components';

interface ISubPageThemeProps {
    children: React.ReactNode;
    headerBg: string;
}

export function SubPageTheme({ children, headerBg }: ISubPageThemeProps) {
    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} md={12}>
                <Header bg={headerBg} />
            </Grid>
            <Content item xs={12} md={12} textAlign="center">
                {children}
            </Content>
            <FooterContainer item xs={12} md={12}>
                <Footer />
            </FooterContainer>
        </Grid>
    );
}

interface IHeaderProps {
    bg: string;
}

const Header = styled.div<IHeaderProps>`
    width: 100%;
    height: 80vh;
    background-image: url('${({ bg }: IHeaderProps) => (bg ? bg : '#6c7f96')}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
`;

const Content = styled(Grid)`
    padding-top: 70px;
`;
const FooterContainer = styled(Grid)`
    padding-top: 30px;
`;
