import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Footer } from '../../Footer';
import { Contact } from '../../Contact';

export function SlideContact() {
    return (
        <Content container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
                <Contact headerVisible={false} />
            </Grid>
            <FooterContainer>
                <Footer />
            </FooterContainer>
        </Content>
    );
}

const FooterContainer = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
`;

const Content = styled(Grid)`
    margin-top: 150px;

    ${(props) => props.theme.breakpoints.up('xl')} {
        margin-top: 350px;
    }
`;
