import React from 'react';
import { Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import logo from '../../resources/icons/icon-15.svg';
import { Grid } from '@mui/material';
import { Paragraph } from '../../components/Paragraph/Paragraph';
import { Button } from '../../components/Button/Button';
import { Link } from 'react-router-dom';
import { SlideServices } from './slides/SlideServices';
import { SlideSpecializations } from './slides/SlideSpecializations';
import { SlideContact } from './slides/SlideContact';
import { Swiper as SwiperClass } from 'swiper/types';
import styled from 'styled-components';
import { Text } from '../../components/Text';
import { SlideAbout } from './slides/SlideAbout';

interface ILandingPageProps {
    activeSlide: number;

    onSlideChange(state: number): void;
}

export const LandingPageDesktop = (props: ILandingPageProps) => {
    function handleOnChangeSlide(swiper: SwiperClass) {
        props.onSlideChange(swiper.activeIndex);
    }

    function renderBullet(index: number, className: any) {
        return `    
            <div class="${className} swiper-bullet-custom"></div>
        `;
    }

    return (
        <Swiper
            mousewheel={true}
            speed={800}
            pagination={{
                clickable: true,
                renderBullet,
            }}
            className="swiper-custom"
            direction={'vertical'}
            slidesPerView={1}
            modules={[Mousewheel, Pagination]}
            onSlideChange={handleOnChangeSlide}
        >
            <SwiperSlide className="landing-page-hello">
                <div className="landing-page-hello__logo">
                    <img src={logo} />
                </div>
                <div className="background" />
            </SwiperSlide>
            <SwiperSlide>
                <SlideAbout />
            </SwiperSlide>
            <SwiperSlide>
                <SlideServices />
            </SwiperSlide>
            <SwiperSlide>
                <SlideSpecializations />
            </SwiperSlide>
            <SwiperSlide>
                <SlideContact />
            </SwiperSlide>
        </Swiper>
    );
};

export const KnowMore = styled(Grid)`
    padding-top: 40px;
`;
