import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { Information } from '../components/Text';

enum Specs {
    SporySadowe = 'spory-sadowe',
    SprawyBankowe = 'sprawy-bankowe',
    Odszkodowania = 'odszkodowania',
    PrawoPracy = 'prawo-pracy',
    Umowy = 'umowy',
    PrawoRodzinne = 'prawo-rodzinne',
    PrawoSpadkowe = 'prawo-spadkowe',
    PrawoKonsumenckie = 'prawo-konsumenckie',
    PostepowanieEgzekucyjne = 'postępowanie-egzekucyjne',
}

const imagesMap = {
    [Specs.SporySadowe]: '/resources/icons/icon-01.png',
    [Specs.SprawyBankowe]: '/resources/icons/icon-02.png',
    [Specs.Odszkodowania]: '/resources/icons/icon-03.png',
    [Specs.PrawoPracy]: '/resources/icons/icon-04.png',
    [Specs.Umowy]: '/resources/icons/icon-05.png',
    [Specs.PrawoRodzinne]: '/resources/icons/icon-06.png',
    [Specs.PrawoSpadkowe]: '/resources/icons/icon-07.png',
    [Specs.PrawoKonsumenckie]: '/resources/icons/icon-08.png',
    [Specs.PostepowanieEgzekucyjne]: '/resources/icons/icon-09.png',
};

const specializations = [
    Specs.SporySadowe,
    Specs.PrawoRodzinne,
    Specs.SprawyBankowe,
    Specs.PrawoSpadkowe,
    Specs.Odszkodowania,
    Specs.PrawoKonsumenckie,
    Specs.PrawoPracy,
    Specs.PostepowanieEgzekucyjne,
    Specs.Umowy,
];

const texts = {
    [Specs.SporySadowe]:
        'Praktyka sporów sądowych obejmuje wszystkie sprawy klientów (cywilne, gospodarcze, administracyjne), które swój finał znajdują w sądzie. Na każdym etapie sprawy staram się prowadzić spór do  porozumienia (ugody), a wszędzie tam, gdzie nie jest to możliwe reprezentuję Klienta w postępowaniu sądowym. Jak pokazuje praktyka, zbudowanie przemyślanej strategii procesowej na wczesnym etapie sporu oraz siła argumentacji prezentowanej na sali sądowej, to podstawa sukcesu Klienta.',
    [Specs.SprawyBankowe]:
        'W imieniu moich Klientów świadczę usługi związane z dochodzeniem roszczeń wobec instytucji finansowych, a także oceniam zasadność żądań kierowanych przez m. in. banki występujące przeciwko swoim klientom. W przypadku powierzenia mi sprawy biorę aktywny udział w procesie przedsądowym (reklamacyjnym) i sądowym. Szczególną uwagę kładę na bieżące śledzenie linii orzeczniczej, co pozwala opracować indywidualną strategię procesową dla moich Klientów.',
    [Specs.Odszkodowania]:
        'Moje wsparcie w odzyskiwaniu odszkodowań obejmuje między innymi: analizę dostarczonej dokumentacji; zgłaszanie roszczeń do towarzystw ubezpieczeniowych; sporządzanie pism – odwołania, pozwy i inne; reprezentację klienta w kontaktach z ubezpieczycielem; reprezentację klienta w trakcie rozpraw sądowych.',
    [Specs.PrawoPracy]:
        'Udzielam pracodawcom porad prawnych, przygotowuję odpowiednią dokumentację oraz reprezentuję klientów w sporach z pracownikami, które obejmuje prawo pracy. Pomagam także pracownikom w dochodzeniu praw oraz reprezentowaniu ich interesów.',
    [Specs.Umowy]:
        'Prawo umów to szeroka i wymagająca specjalistycznej wiedzy gałąź prawa. O tym czy kontrakt spełni swoją funkcję i zapewni Klientowi możliwość skutecznej realizacji jego celu, mogą zadecydować pozornie nieistotne szczegóły. Podejmując się realizacji dużego przedsięwzięcia, warto więc zadbać przede wszystkim o bezpieczeństwo prawne. Oferuję pomoc na każdym z etapów, związanych z realizacją przedmiotu kontraktu.',
    [Specs.PrawoRodzinne]:
        'Z pełnym zaangażowaniem prowadzę sprawy z zakresu prawa rodzinnego. Mam pełną świadomość, że problematyka związana np. z rozwodem lub władzą rodzicielską nad dziećmi wywołuje wśród Klientów wiele emocji. Dlatego gwarantuję moim Klientom profesjonalne świadczenie usług w przyjaznej dla nich atmosferze – poczucia bezpieczeństwa, zaufania i dyskrecji.',
    [Specs.PrawoSpadkowe]:
        'Świadczę kompleksową pomoc prawną w zakresie prawa spadkowego, w tym udzielam porad prawnych oraz reprezentuję Klientów przed sądami. Sprawy spadkowe mają szczególny charakter i do każdej sprawy podchodzę indywidualnie.',
    [Specs.PrawoKonsumenckie]:
        'Świadczę usługi prawne dla osób fizycznych oraz podmiotów prawnych, zarówno na etapie postępowania reklamacyjnego jak i postępowania sądowego. Oferuję pomoc m.in. w zakresie: sprzedaży konsumenckiej i dochodzenia roszczeń z nią związanych; zawierania umów na odległość i poza lokalem przedsiębiorstwa; analizy umów i regulaminów świadczenia usług pod kątem klauzul abuzywnych; sporządzenia regulaminów sklepów internetowych.',
    [Specs.PostepowanieEgzekucyjne]:
        'Praktyczna znajomość przepisów o egzekucji to z perspektywy wierzyciela szybkie i sprawne odzyskanie pieniędzy. Z kolei z perspektywy dłużnika gwarancja ochrony prawnej przed niekontrolowaną windykacją, w tym możliwością naruszenia praw dłużnika i osób trzecich, których dotykają skutki prowadzonej egzekucji.',
};

export const Specializations = () => {
    const [active, setActive] = React.useState(Specs.SporySadowe);

    const handleChangeSpec = (spec: Specs) => () => {
        setActive(spec);
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item md={12}>
                <Grid container justifyContent="center" alignItems="center">
                    {specializations.map((spec) => (
                        <Icon
                            active={active === spec}
                            onClick={handleChangeSpec(spec)}
                            onMouseEnter={handleChangeSpec(spec)}
                            index={spec}
                        />
                    ))}
                </Grid>
            </Grid>
            <Grid item md={8} justifyContent="center" textAlign="center" className="landing-page-specs__texts">
                {specializations.map((spec) => (
                    <Text active={active === spec}>{texts[spec]}</Text>
                ))}
            </Grid>
        </Grid>
    );
};

function Icon(props: any) {
    return (
        <ImageContainer {...props}>
            {/* @ts-ignore*/}
            <Image src={imagesMap[props.index]} />
        </ImageContainer>
    );
}

function Text(props: any) {
    return (
        <TextContainer visible={props.active}>
            <Information>{props.children}</Information>
        </TextContainer>
    );
}

interface ITextContainerProps {
    visible: boolean;
}

const TextContainer = styled.div`
    position: absolute;
    top: 50px;
    left: 0;

    line-height: 35px;

    transition: all 0.5s ease-in;
    transition-delay: ${({ visible }: ITextContainerProps) => (visible ? '.5s' : '0')};
    opacity: ${({ visible }: ITextContainerProps) => (visible ? '1' : '0')};

    ${(props) => props.theme.breakpoints.up('xl')} {
        top: 100px;
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        top: 30px;
    }
`;

const ImageContainer = styled.div<{ active?: boolean }>`
    width: 130px;
    height: 130px;
    margin-right: -30px;

    display: inline-block;

    cursor: pointer;
    transition: all 0.2s ease-in;
    transform: rotate(45deg) ${(props) => (props.active ? 'scale(1.1)' : '')};

    opacity: ${(props) => (props.active ? '1' : '.7')};

    &:nth-child(even) {
        margin-top: 190px;
    }

    &:hover {
        transform: scale(1.1) rotate(45deg);
    }

    & img {
        transform: rotate(-45deg);
    }

    ${(props) => props.theme.breakpoints.down('lg')} {
        width: 115px;
        height: 115px;
    }
`;

const Image = styled.img`
    max-width: 100%;
`;
